var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ValidationObserver',{ref:"stepTwo"},[_c('form',{staticClass:"theme-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{attrs:{"for":"idType"}},[_vm._v(" "+_vm._s('Fournir votre CIN ou NIF')+" ")]),_c('ValidationProvider',{attrs:{"rules":"required","customMessages":{
                    required: 'Le champs CIN ou NIF  est obligatoire'
                  },"name":"idType"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{staticClass:"style-chooser",attrs:{"id":"idType","options":_vm.identificationTypes,"name":"idType","label":"abbrev"},model:{value:(_vm.form.idType),callback:function ($$v) {_vm.$set(_vm.form, "idType", $$v)},expression:"form.idType"}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"cin_nif"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.cin_nif),expression:"form.cin_nif"},{name:"maska",rawName:"v-maska",value:(_vm.nifMask),expression:"nifMask"}],staticClass:"form-control",attrs:{"type":"text","id":"cin_nif","placeholder":_vm.nifMask.replaceAll('#', '0'),"name":"cin_nif"},domProps:{"value":(_vm.form.cin_nif)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "cin_nif", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{attrs:{"for":"remuneration"}},[_vm._v("Type de rémunération")]),_c('ValidationProvider',{attrs:{"rules":"required","customMessages":{
                    required: 'Le champs Type de rémunération est obligatoire'
                  },"name":"Remuneration"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{staticClass:"style-chooser",attrs:{"id":"remuneration","options":_vm.paymentTypes,"name":"remuneration","label":"name"},model:{value:(_vm.form.remuneration),callback:function ($$v) {_vm.$set(_vm.form, "remuneration", $$v)},expression:"form.remuneration"}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),(_vm.payInfo)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{attrs:{"for":"bank"}},[_vm._v("Quel Banque?")]),_c('ValidationProvider',{attrs:{"rules":"required","customMessages":{
                    required: 'Quel Banque?'
                  },"name":"bank"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{staticClass:"style-chooser",attrs:{"id":"bank","options":_vm.banques,"name":"bank","label":"name"},model:{value:(_vm.form.bank),callback:function ($$v) {_vm.$set(_vm.form, "bank", $$v)},expression:"form.bank"}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,43150918)})],1)]):_vm._e(),(_vm.payInfo)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{attrs:{"for":"account_number"}},[_vm._v("Numéro du Compte")]),_c('ValidationProvider',{attrs:{"rules":"required","customMessages":{
                    required: 'Le Numéro du Compte bancaire?'
                  },"name":"account_number"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.account_number),expression:"form.account_number"},{name:"maska",rawName:"v-maska",value:(_vm.bankMask),expression:"bankMask"}],staticClass:"form-control",attrs:{"type":"text","id":"account_number","placeholder":_vm.bankMask,"name":"account_number"},domProps:{"value":(_vm.form.account_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "account_number", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2915646822)})],1)]):_vm._e()])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Identifiant *")]),_c('ValidationProvider',{attrs:{"rules":"required|username_regex","customMessages":{
                    required: 'Le champs Nom d\'utilisateur est obligatoire',
                    username_regex : 'Nom d\'utilisateur invalide'
                  },"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",attrs:{"type":"text","id":"name","placeholder":"Nom d'utilisateur","name":"name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-row"},[(!_vm.godSon)?_c('div',{staticClass:"col-md-6"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Mot de Passe *")]),_c('ValidationProvider',{attrs:{"rules":"required","vid":"password","customMessages":{
                    required: 'Mot de passe utilisateur?'
                  },"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",attrs:{"type":"password","id":"password","placeholder":"Entrer votre Mot de Passe","name":"password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3208023188)})],1):_vm._e(),(!_vm.godSon)?_c('div',{staticClass:"col-md-6"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Confirmer Mot de Passe")]),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","vid":"confirmPassword","customMessages":{
                    required: 'Confirmer Mot de passe',
                    confirmed : 'Les champs mot de passe ne correspondent pas'
                  },"name":"confirm_password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",attrs:{"type":"password","id":"confirmPassword","placeholder":"Confirmer votre Mot de Passe","name":"confirm_password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2911180800)})],1):_vm._e()]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false } },"customMessages":{
                    required: 'Vous devez lire les conditions d\'utilisateurs',
                  },"name":"agreed"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticStyle:{"text-transform":"none"},attrs:{"for":"agreed"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.agreed),expression:"form.agreed"}],attrs:{"type":"checkbox","id":"agreed","name":"agreed"},domProps:{"checked":Array.isArray(_vm.form.agreed)?_vm._i(_vm.form.agreed,null)>-1:(_vm.form.agreed)},on:{"change":function($event){var $$a=_vm.form.agreed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "agreed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "agreed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "agreed", $$c)}}}}),_vm._v(" J’accepte "),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-privacy",modifiers:{"modal-privacy":true}}],staticStyle:{"color":"blue"}},[_vm._v("les conditions d’intégration au programme")]),_vm._v(" d’ASCENSION GROUP")]),_c('br'),(errors[0])?_c('span',{staticClass:"validate-error"},[_vm._v("Confirmez avoir lu le conditions d'utilisation.")]):_vm._e()]}}])})],1)])])]),_c('b-modal',{attrs:{"id":"modal-privacy","size":"lg","centered":"","title":"Add-to-cart","hide-footer":false,"hide-header":true,"ok-only":"","no-close-on-backdrop":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('privacy')],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }