import axios from "axios";

import step1 from "../views/page/account/registerComponent/step1";
import step2 from "../views/page/account/registerComponent/step2";

//local registration
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters } from "vuex";

const customerMixin = {
  components: {
    FormWizard,
    TabContent,

    step1,
    step2
  },

  data() {
    return {
      finalForm: {}
    };
  },

  computed: {
    ...mapGetters("auth", ["isLogged", "user"])
  },

  methods: {
    // ...mapActions({
    //   getCustomerDetails: "customer/fetchCustomers"
    // }),

    validateStep(name) {
      let refToValidate = this.$refs[name];
      return refToValidate.onSubmit();
    },

    mergePartialModels(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalForm = Object.assign({}, this.finalForm, model);
      }
    },

    async saveCustomer(godFather = false) {
      // let _self = this
      let refToValidate = this.$refs["step2"];
      const valid = await refToValidate.onSubmit();
      if (valid.isvalid) {

        let loader = this.$loading.show()

        this.finalForm = Object.assign({}, this.finalForm, valid.model);
        if(this.$route.params.customer){
          this.finalForm = {...this.finalForm, reference: this.$route.params.customer};
        }

        if(this.godFather){
          this.finalForm = {...this.finalForm, reference: this.user};
        }
        axios.post("customers", this.finalForm)
          .then(data => {
            if (data.data.success) {
              if (godFather===true) {
                loader.hide()
                this.$swal('Enregistré avec succes',
                  'L\'inscription de votre protégé a été enregistrée avec succès, que votre protégé sa boite mail pour de plus amples informations',
                  'success');
                this.$router.push({ path: "/dashboard" });
              } else {
                loader.hide()
                this.$swal('Enregistré avec succes',
                  ' Votre inscription a été enregistrée avec succès, veuillez consulter votre boite mail pour de plus amples informations.',
                  'success');
                this.$router.push({ path: "/login" });
              }
            }
          })
          .catch(error => {
              if (error.response) {
                loader.hide();
                this.$swal("Oops",
                    error.response.data.message,
                    "error");
              }
            });
              /*err => {
            loader.hide()
            console.log('error', err);
            this.$swal("Oops",
                isLogged.message,
                "error");
          }*/
          //);
      }
    }
  }
};

export default customerMixin;
