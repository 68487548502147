<template>
    <div style="font-size: 18px;">
      <h3 style="text-align: center"><b>Conditions d’intégration</b></h3>
      <br/><br/>1-	Le présent document est le règlement qui fixe les conditions d’intégration d’une personne au programme de promotion et de vente d’ASCENSION GROUP (AG).

      <br/><br/>2-	Toute personne dont l’inscription au programme est validée détient automatiquement le statut d’agent promoteur indépendant (API). Les frais d’inscription ne sont pas remboursables.

      <br/><br/>3-	Un API est un entrepreneur qui fait affaires avec AG. En investissant son argent, son temps et en exerçant son leadership pour construire son réseau, il ne fait que créer son propre entreprise. Il est un agent marketing indépendant auquel AG accorde le droit de faire la promotion de son programme et de ses produits. Il est autorisé à vendre ses produits et à recruter d’autres API pour construire son réseau. En contrepartie il bénéficie chaque mois des bonus, des primes leadership, des salaires et des commissions suivant les conditions établies par la présente et celles du programme de promotion et de vente.

      <br/>3.1-	 Un API n’est pas un employé d’ASCENSION GROUP. Comme son nom l’indique c’est un agent indépendant qui bénéficie seulement de l’opportunité qu’offre AG. Par conséquent, en aucun cas, AG n’est tenue aux exigences relatives aux rapports d’une entreprise à un employé.

      <br/><br/>4-	Pour être API, une personne doit atteindre l’âge de la maturité conformément aux lois haïtiennes.

      <br/><br/>5-	Au moment de l’inscription, les API sont tenus de donner des informations vraies et correctes. AG n’est responsable d’aucune conséquence ou éventuel problème qu’un API fait face en raison d’informations frauduleuses ou erronées qu’il a données lors de son inscription.

      <br/><br/>6-	 L’engagement d’un API au programme de promotion et de vente est personnel. AG ne reconnait que lui et lui seul. Aucune autre personne pour quelque raison qu’il soit ne peut décider à sa place.

      <br/><br/>7-	Dès qu’une personne dispose du statut d’API, cela implique automatiquement son accord au règlement et aux conditions qui régissent le programme.

      <br/><br/>8-	Un API autorise AG à envoyer sur son e-mail et son numéro de téléphone des messages écrits, des documents audio, audiovisuels, des publicités relatives à l’entreprise et ses activités.

      <br/><br/>9-	 Un API autorise AG à prélever les taxes exigées par les autorités compétentes de l’État haïtiens.

      <br/><br/>10-	Les API sont les seules personnes autorisées à acheter directement nos produits dans nos rayons de ventes.

      <br/><br/>11-	L’argent reçu par AG pour une commande placée par un API n’est pas remboursable. Dans certains cas l’administration peut permettre le choix d’autres produits à la place de ceux préalablement choisis.

      <br/><br/>12-	Les commandes d’un API et l’incription (directe) de nouveaux membres dans son réseau doivent impérativement être effectuées sur son compte personnel (bureau virtuel). Dans le cas contraire, c’est celui sur le bureau duquel l’opération ou la transaction a eu lieu qui en beneficiera les avantages et prérogatives.

      <br/><br/>13-	Un API ne peut recevoir aucune commande et aucun chèque sans son licence.

      <br/>13.1-	Les licences perdues sont reproduits par AG aux frais des API. L’administration fixe les frais à payer.

      <br/><br/>14-	Pour bénéficier les avantages qu’offre le programme, les API doivent respecter les conditions du programme telles que établies par AG.

      <br/><br/>15-	Conformément aux conditions du programme et au présent règlement, un API bénéficie à vie et de génération en génération les intérêts, les privilèges et prérogatives que lui confère son entreprise aussi longtemps que perdure ASCENSION GROUP. Mais sous peine de ne plus pouvoir les bénéficier, ils sont tenus de renouveler leur licence chaque année.

      <br/><br/>16-	 Un API peut désigner un héritier pour son entreprise. Mais il peut le faire seulement après avoir atteint le niveau IV dans la structure du programme de promotion. L’héritier bénéficie son héritage suivant les termes du présent règlement ou sa version modifiée et les conditions du programme.

      <br/>16.1-	L’héritier d’un API jouit les prérogatives de son héritage après la mort de celui-ci ou après la signature du document formel de passation d’héritage conformément aux procédures établies.

      <br/><br/>17-	Dignité, intégrité, solidarité, réussite, bien-être sont les valeurs fondamentales de l’entreprise. Dans le cadre des activités relatives au programme, les API sont tenus au respect de ces valeurs.

      <br/><br/>18-	AG peut mettre fin au contrat qui le lie à un API dans les cas où celui-ci ne respecte pas l’une des clauses. Et en mettant fin au contrat, AG n’a aucune redevance et responsabilité envers l’ancien API.

      <br/><br/>19-	Un API faisant usage de faux, de manipulation, de vol ou qui ternit l’image de AG par des actes infâmes dans le cadre du programme est passible d’expulsion.

      <br/><br/>20-	 En faisant le choix d'une modalité de paiement pour effectuer ses transactions, c'est-à-dire payer ou recevoir de l'argent, l'agent promoteur indépendant (API) accepte par la même occasion de verser, s'il y en a, les frais de service liés au moyen de paiement choisi. En aucun cas AG n'endosse la responsabilité de ces frais.

      <br/>20.1-	Lorsqu'il fait le choix d'une modalité de paiement requérant des frais de service,  un API autorise Ascension Group à prélever directement ces dits frais si nécessaire.

      <br/><br/>21-	Toute demande d'activation ou de validation dont le traitement requiert l'intervention manuelle d'un administrateur sur le système doit être faite dans un délai raisonnable afin que l'administration de l'entreprise puisse avoir le temps de le traiter. Ascension Group ne répond d'aucune responsabilité des demandes d'activation ou de validation requérant l'intervention manuelle d'un administrateur qui n'ont pas été formulées dans un délai raisonnable.

      <br/>21.1-	Pour qu'elle soit traitée ou considérée traitée le même jour, une demande d'activation ou de validation dont le traitement requiert l'intervention manuelle d'un administrateur sur le système doit être faite au moins deux (2) heures de temps avant l'heure de fermeture de l’entreprise.

      <br/><br/>22-	 Est considérée comme demande d'activation ou de validation toute présentation physique ou virtuelle d'une preuve de paiement, d'une transaction effectuée sur le système, qui soit conforme aux procédures établies par Ascension Group.

      <br/><br/>23-	 Aucune demande d'activation ou de validation dont le traitement requiert l'intervention manuelle d'un administrateur sur le système ne sera traitée au cours d'un jour non ouvrable (jour de repos) et/ou férié.

      <br/>23.1-	Toutes demandes formulées en ces jours devront attendre le prochain jour ouvrable pour être traités. Par conséquent, un API au profit des intérêts duquel le traitement d'une demande est urgent, ne doit pas la formuler durant ces jours. Il doit la faire avant. Sinon, il perdra ou pourra perdre les avantages que lui procurerait le traitement de sa demande.

      <br/><br/>24-	AG dispose du droit de modifier le présent règlement ainsi que le programme de promotion et de vente. Il dispose également du droit de statuer sur tout cas n’ayant pas été prévu dans le programme.

      <br/><br/>25-	Ce règlement tient lieu de contrat entre AG et toute personne inscrite au programme et sert à qui de droit.

    </div>
</template>
<script type="text/javascript">
    export default {
        name: 'privacy.vue',

        data() {return {}},
        mounted(){}
    }
</script>
<style>
.modal-content {
  background: white;
}
</style>
