<template>
  <div>
    <ValidationObserver ref="stepTwo">
        <form class="theme-form" @submit.prevent="onSubmit">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-row">
                        <div class="col-md-12">
                            <label for="idType">
                                {{ 'Fournir votre CIN ou NIF' }}
                            </label>
                            <ValidationProvider rules="required" :customMessages="{
                      required: 'Le champs CIN ou NIF  est obligatoire'
                    }" v-slot="{ errors }" name="idType">
                                <v-select id="idType"
                                          :options="identificationTypes"
                                          name="idType"
                                          class="style-chooser"
                                          label="abbrev"
                                          v-model="form.idType"
                                />
                              <span class="validate-error" >{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12">
                            <ValidationProvider rules="required" v-slot="{ errors }" name="cin_nif">
                                <input
                                        type="text"
                                        class="form-control"
                                        id="cin_nif"
                                        v-model="form.cin_nif"
                                        :placeholder="nifMask.replaceAll('#', '0')"
                                        name="cin_nif"
                                        v-maska="nifMask"
                                />
                              <span class="validate-error" >{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12">
                            <label for="remuneration">Type de rémunération</label>
                            <ValidationProvider rules="required" :customMessages="{
                      required: 'Le champs Type de rémunération est obligatoire'
                    }" v-slot="{ errors }" name="Remuneration">
                                <v-select
                                        id="remuneration"
                                        :options="paymentTypes"
                                        name="remuneration"
                                        class="style-chooser"
                                        label="name"
                                        v-model="form.remuneration"/>
                              <span class="validate-error" >{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="form-row" v-if="payInfo">
                        <div class="col-md-12">
                            <label for="bank">Quel Banque?</label>
                            <ValidationProvider rules="required" :customMessages="{
                      required: 'Quel Banque?'
                    }" v-slot="{ errors }" name="bank">
                                <v-select
                                        id="bank"
                                        :options="banques"
                                        name="bank"
                                        class="style-chooser"
                                        label="name"
                                        v-model="form.bank"/>
                              <span class="validate-error" >{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="form-row" v-if="payInfo">
                        <div class="col-md-12">
                            <label for="account_number">Numéro du Compte</label>
                            <ValidationProvider rules="required" :customMessages="{
                      required: 'Le Numéro du Compte bancaire?'
                    }" v-slot="{ errors }" name="account_number">
                                <input
                                        type="text"
                                        class="form-control"
                                        id="account_number"
                                        v-model="form.account_number"
                                        :placeholder="bankMask"
                                        name="account_number"
                                        v-maska="bankMask"
                                />
                              <span class="validate-error" >{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <!--<div class="form-row" v-if="mblnumber">-->
                        <!--<div class="col-md-12">-->
                            <!--<label for="mobile_number">Numero Mobile</label>-->
                            <!--<ValidationProvider rules="required" v-slot="{ errors }" name="mobile_number">-->
                                <!--<input-->
                                        <!--type="text"-->
                                        <!--class="form-control"-->
                                        <!--id="mobile_number"-->
                                        <!--v-model="form.mobile_number"-->
                                        <!--placeholder="Numero du Mobile..."-->
                                        <!--name="mobile_number"-->
                                <!--/>-->
                              <!--<span class="validate-error" >{{ errors[0] }}</span>-->
                            <!--</ValidationProvider>-->
                        <!--</div>-->
                    <!--</div>-->
                </div>
                <!--<div class="col-md-6">-->
                <!--<div class="form-row">-->
                <!--<div class="col-md-12">-->
                <!--<label for="reference">Code de Reference</label>-->
                <!--<input-->
                <!--type="text"-->
                <!--class="form-control"-->
                <!--id="reference"-->
                <!--v-model="form.reference"-->
                <!--placeholder="Code de Reference..."-->
                <!--name="reference"-->
                <!--:disabled="godSon"-->
                <!--/>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
            </div>


            <div class="form-row">
                <div class="col-md-12">
                    <label for="name">Identifiant *</label>
                    <ValidationProvider rules="required|username_regex" :customMessages="{
                      required: 'Le champs Nom d\'utilisateur est obligatoire',
                      username_regex : 'Nom d\'utilisateur invalide'
                    }" v-slot="{ errors }" name="Name">
                        <input
                                type="text"
                                class="form-control"
                                id="name"
                                v-model="form.name"
                                placeholder="Nom d'utilisateur"
                                name="name"
                        />
                      <span class="validate-error" >{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6" v-if="!godSon">
                    <label for="password">Mot de Passe *</label>
                    <ValidationProvider rules="required"  vid="password" :customMessages="{
                      required: 'Mot de passe utilisateur?'
                    }" v-slot="{ errors }" name="password">
                        <input
                                type="password"
                                class="form-control"
                                id="password"
                                v-model="form.password"
                                placeholder="Entrer votre Mot de Passe"
                                name="password"
                        />
                      <span class="validate-error" >{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="col-md-6" v-if="!godSon">
                    <label for="password">Confirmer Mot de Passe</label>
                    <ValidationProvider rules="required|confirmed:password" vid="confirmPassword" :customMessages="{
                      required: 'Confirmer Mot de passe',
                      confirmed : 'Les champs mot de passe ne correspondent pas'
                    }" v-slot="{ errors }" name="confirm_password">
                        <input
                                type="password"
                                class="form-control"
                                id="confirmPassword"
                                v-model="confirmPassword"
                                placeholder="Confirmer votre Mot de Passe"
                                name="confirm_password"
                        />
                      <span class="validate-error" >{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12">
                    <ValidationProvider :rules="{ required: { allowFalse: false } }" :customMessages="{
                      required: 'Vous devez lire les conditions d\'utilisateurs',
                    }" v-slot="{ errors }" name="agreed">
                        <label for="agreed" style="text-transform: none;">
                            <input
                                    type="checkbox"
                                    id="agreed"
                                    v-model="form.agreed"
                                    name="agreed"
                            />
                            J’accepte <a style="color: blue" v-b-modal.modal-privacy>les conditions d’intégration
                            au programme</a> d’ASCENSION GROUP</label>
                      <br/>
                      <span class="validate-error" v-if="errors[0]">Confirmez avoir lu le conditions d'utilisation.</span>
                    </ValidationProvider>

                </div>
            </div>
        </form>
    </ValidationObserver>
    <b-modal
        id="modal-privacy"
        size="lg"
        centered
        title="Add-to-cart"
        :hide-footer="false"
        :hide-header="true"
        ok-only
        no-close-on-backdrop

    >
      <div class="row">
        <div class="col-lg-12">
          <privacy />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>


  import { ValidationObserver, ValidationProvider, extend} from "vee-validate/dist/vee-validate.full.esm";
  import axios from "axios";
  import { maska } from "maska";
  import { mapGetters } from "vuex";
  import Privacy from "../../../../components/privacy/privacy";

  extend('username_regex', {
    validate: value => {
      const strongRegex = new RegExp("^[\\wÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ._-]{3,18}$");
      return strongRegex.test(value);
    }
  })

  export default {
    name: "step2",

    directives: { maska },

    components: {
      Privacy,
      ValidationProvider,
      ValidationObserver,
    },

    props: {
      godSon: {
        required: true,
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        showPrivacy: false,
        paymentTypes: [],
        banques: [],
        identificationTypes: [],

        payInfo: false,

        bankMask: "",
        nifMask: "",

        form: {
          idType: "",
          cin_nif: "",

          remuneration: "",
          bank: "",
          account_number: "",

          reference: "",

          name: "",
          password: "",

          agreed: false
        },

        confirmPassword: ''
      };
    },

    methods: {

      //showModal() {
        //this.$emit('opencartmodel', this.cartval, this.cartProduct)
        //$('#modal-privacy').modal('show');
      //},

      async onSubmit() {
        const valid = await this.$refs.stepTwo.validate();
        if (!valid) {
          return;
        }

        if (!this.godSon) {
          this.form.reference = "DIRECT_SUBSCRIPTION";
        }
        // this.$emit('on-validate', this.form, valid)
        return { model: this.form, isvalid: valid };
      },

      getPaymentTypes() {
        return new Promise((resolve, reject) => {
          axios.get("payment_types")
            .then(data => {
              if (data.data.success) {
                console.log("payment_types", data.data.data);
                this.paymentTypes = data.data.data.payroll;
                resolve(data);
              }
            }).catch(err => {
            console.log(err);
            reject(err);
          });
        });
      },

      getIdentificationTypes() {
        return new Promise((resolve, reject) => {
          axios.get("identification_types")
            .then(data => {
              if (data.data.success) {
                this.identificationTypes = data.data.data;
                resolve(data);
              }
            }).catch(err => {
            console.log(err);
            reject(err);
          });
        });
      },

      getBanques() {
        return new Promise((resolve, reject) => {
          axios.get("banks")
            .then(data => {
              if (data.data.success) {
                this.banques = data.data.data;
                resolve(data);
              }
            }).catch(err => {
            console.log(err);
            reject(err);
          });
        });
      },

      parrainage(){
        this.form.reference = this.$route.params.customer ? this.$route.params.customer : ''
      }
    },

    watch: {
      "form.remuneration": {
        immediate: true,
        handler: function(val) {
          if (val) {
            console.log("AL", val);
            const r = typeof val.payment_type.options === 'object' ? val.payment_type.options : JSON.parse(val.payment_type.options);
            if(r.bank_num || r.mobile_number){
              this.payInfo = true
            }else{
              this.payInfo = false
            }
          } else {
            this.payInfo = false
          }
        }
      },

      "form.bank": {
        immediate: true,
        handler: function(val) {
          if (val) {
            this.bankMask = val.maskFormat;
          } else {
            this.bankMask = "";
          }
        }
      },

      "form.idType": {
        immediate: true,
        handler: function(val) {
          if (val) {
            this.nifMask = val.maskFormat;
          } else {
            this.nifMask = "";
          }
        }
      },

      customer: {
        immediate: true,
        handler: function(val) {
          if (this.godSon) {
            this.form.reference = val.code;
          }
        }
      }
    },

    computed: {
      ...mapGetters({
        customer: "auth/user"
      }),

    },

    mounted() {
      this.getIdentificationTypes();
      this.getPaymentTypes();
      this.getBanques();
      this.parrainage()
    }
  };
</script>

<style>

</style>
