<template>
    <ValidationObserver ref="stepOne">
        <form class="theme-form" @submit.prevent="onSubmit">
            <div class="form-row">
                <div class="col-md-6">
                    <label for="First name">Prénom</label>
                    <ValidationProvider rules="required|name_regex" :customMessages="{
                      required: 'Le champs Prénom est obligatoire',
                      name_regex : 'format incorrect'
                    }" v-slot="{ errors }" name="First name">
                        <input
                                type="text"
                                class="form-control"
                                id="First name"
                                v-model="form.fname"
                                placeholder="Prenom"
                                name="First name"
                        />
                        <span class="validate-error" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="col-md-6">
                    <label for="lname">Nom de famille</label>
                    <ValidationProvider rules="required|name_regex" :customMessages="{
                      required: 'Le champs Nom est obligatoire',
                      name_regex : 'format incorrect'
                    }" v-slot="{ errors }" name="Last name">
                        <input
                                type="text"
                                class="form-control"
                                id="lname"
                                v-model="form.lname"
                                placeholder="Nom de famille"
                                name="lname"
                        />
                        <span class="validate-error" v-if="errors[0]">{{  errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6">
                    <label>Date de Naissance</label>
                    <div>
                        <ValidationProvider rules="required" :customMessages="{
                      required: 'Le champs Date de naissance est obligatoire'
                    }" v-slot="{ errors }" name="dob">
                            <date-picker
                                    v-model="form.dob"
                                    valueType="format"
                                    :input-attr="{name : 'dob', id: 'dob'}"
                                    input-class="form-control"
                                    style="width: 100%"
                                    :disabled-date="disabledBeforeEignteen"
                            />
                            <span class="validate-error" v-if="errors[0]">{{ errors[0]  }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="sex">Sexe</label>
                    <ValidationProvider rules="required" :customMessages="{
                      required: 'Le champs Sexe est obligatoire'
                    }" v-slot="{ errors }" name="sex">
                        <v-select id="sex"
                                  :options="[{label: 'Masculin', value: 'male'}, {label: 'Feminin', value:'female'}]"
                                  name="sex"
                                  class="style-chooser"
                                  v-model="form.gender"
                                  label="label"
                        />
                        <span class="validate-error" >{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6">
                    <label for="marital">Statut Matrimonial</label>
                    <ValidationProvider rules="required" :customMessages="{
                      required: 'Le champs Statut Matrimonial est obligatoire'
                    }" v-slot="{ errors }" name="marital">
                        <v-select id="marital"
                                  :options="maritals"
                                  name="marital"
                                  class="style-chooser"
                                  v-model="form.marital_status"
                                  label="label"
                        />
                        <span class="validate-error" >{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="col-md-6">
                    <label for="city">Ville</label>
                    <ValidationProvider rules="required" :customMessages="{
                      required: 'Le champs Ville est obligatoire'
                    }" v-slot="{ errors }" name="city">
                        <v-select id="city"
                                  :options="cities"
                                  name="city"
                                  class="style-chooser"
                                  label="name"
                                  v-model="form.city"
                        />
                        <span class="validate-error" >{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6">
                    <label for="email">Email</label>
                    <ValidationProvider rules="required|email" :customMessages="{
                      required: 'Le champs Email est obligatoire',
                      email: 'Votre Email n\'est pas valide'
                    }" v-slot="{ errors }" name="Email">
                        <input
                                type="email"
                                class="form-control"
                                id="email"
                                v-model="form.email"
                                placeholder="Email"
                                name="email"
                        />
                        <span class="validate-error" >{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="col-md-6">
                    <label for="phone">Téléphone</label>
                    <ValidationProvider rules="required|phone_regex" :customMessages="{
                      required: 'Le champs Téléphone est obligatoire',
                      phone_regex: 'Format inapproprié'
                    }" v-slot="{ errors }" name="phone">
                        <input
                                type="text"
                                class="form-control"
                                id="phone"
                                v-model="form.phone"
                                :placeholder="phoneMask"
                                v-maska="phoneMask"
                                name="phone"
                        />
                        <span class="validate-error" >{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6">
                    <label for="address">Adresse</label>
                    <ValidationProvider rules="required|adress_regex" :customMessages="{
                      required: 'Le champs Adresse est obligatoire',
                      adress_regex: 'Format inapproprié'
                    }" v-slot="{ errors }" name="address">
                        <textarea class="form-control" id="address"
                                  placeholder="#, rue, zône ou quartier"
                                  name="address" v-model="form.bill_address">
                        </textarea>
                        <span class="validate-error" >{{ errors[0] }}</span>
                    </ValidationProvider>
                    <!--<br/> <br/>-->
                    <!--<label class="checkbox-inline">-->
                        <!--<input-->
                                <!--type="checkbox"-->
                                <!--v-model="form.one_address"-->
                        <!--&gt; Meme addresse pour la livraison-->
                    <!--</label>-->
                </div>

                <!--<div class="col-md-6" v-if="!form.one_address">-->
                    <!--<label for="address">Adresse de Livraison</label>-->
                    <!--<textarea class="form-control" id="ship_address"-->
                              <!--placeholder="#, Rue x, zone, section, commune, department, HT"-->
                              <!--name="ship_address" v-model="form.ship_address">-->
                                        <!--</textarea>-->
                <!--</div>-->
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
  import {ValidationProvider, ValidationObserver, extend } from "vee-validate/dist/vee-validate.full.esm";

  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import { addYears, format } from "date-fns";
  import "vue2-datepicker/locale/fr";
  import axios from "axios";
  import {maska} from "maska";

  // import { extend } from "vee-validate";
  // import _ from "lodash";

  extend('name_regex', {
    validate: value => {
      const strongRegex = new RegExp("^[\\w\\s.\\-À-ù]+$");
      return strongRegex.test(value);
    }
  })

 extend('phone_regex', {
    validate: value => {
      const strongRegex = new RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{2}[-\\s\\.]??[0-9]{2}[-\\s\\.]?[0-9]{4}$");
      return strongRegex.test(value);
    }
  });

  extend('adress_regex', {
    validate: value => {
      const strongRegex = new RegExp("^[\\w\\s.,\\-À-ù]+$");
      return strongRegex.test(value);
    }
  });

  export default {
    name: "step1",

    directives: { maska },

    props: {
      godSon: {
        required: true,
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        phoneMask : "+509 ## ##-####",

        cities: [],
        maritals: [
          {
            label: "Marie(e)", value: "married"
          },
          {
            label: "Celibataire", value: "single"
          },
          {
            label: "Veuf(ve)", value: "widow"
          },
          {
            label: "Divorce(e)", value: "divorced"
          }
        ],

        form: {
          fname: null,
          lname: null,
          city: "",
          marital_status: "",
          gender: "",
          dob: format(addYears(new Date(), -18), "yyyy-MM-dd"),
          email: null,
          bill_address: "",
          ship_address: "",
          one_address: true
        }

      };
    },

    components: {
      ValidationProvider,
      ValidationObserver,
      DatePicker,


    },

    watch: {},

    methods: {
      disabledBeforeEignteen(date) {
        const eighteen = addYears(new Date(), -18);
        return date > eighteen;
      },

      async onSubmit() {
        const valid = await this.$refs.stepOne.validate();
        if (!valid) {
          return;
        }
        this.$emit("on-validate", this.form, valid);
        return valid;
      },

      getCities() {
        return new Promise((resolve, reject) => {
          axios.get("boundaries")
            .then(data => {
              if (data.data.success) {
                this.cities = data.data.data;
                resolve(data);
              }
            }).catch(err => {
            console.log(err);
            reject(err);
          });
        });
      }
    },

    mounted() {
      this.getCities();
    }
  };
</script>

<style scoped>

</style>
